.add-button {
  background-color: var(--green);
  color: rgb(245, 245, 245);
  margin-left: 1em !important;
}

.submit-button {
  margin: .5em 0 0 0 !important;
  width: 100%;
}

.nav-button {
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.67)
}

.view-button {
  margin-top: 1em !important;
  margin-left: 1em !important;
}

.mat-paginator-icon {
  color: var(--blue);
}

.section-header-button {
  margin-left: 1em !important;
}

.home-button {
  color: rgba(0, 0, 0, 0.87);
}

.edit-button {
  background-color: var(--green);
  color: rgb(245, 245, 245);
}

.add-icon-button {
  color: var(--green);
}

.inline-icon-button {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.dialog-button {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

button:disabled {
  opacity: 0.5;
}