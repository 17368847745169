section {
    display: flex;
    width: 30%;
    flex-direction: row !important;
    min-width: 350px;
}

mat-form-field {
    flex: auto;
}

.company-panel {
    min-width: 500px !important;
    width: 60% !important;
}

mat-expansion-panel {
    margin: .25em 0 !important;
}

.mat-expansion-indicator::after {
    color: var(--blue);
}

.form-card {
    width: 50%;
    min-width: 600px;
    margin-bottom: 1em;
}

.form-text {
    font-size: 14px;
}

.align-form-field {
    padding-top: 1em !important;
    height: 100% !important;
}

.overlay {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

fieldset {
    border: transparent;
}

.short-section {
    width: 25%;
    min-width: 350px;
    font-size: small;
}