.view-information-toolbar {
  display: flex;
}

.view-information-card {
  margin-top: 2em;

}

.view-information-table-title {
  font-weight: 450;
  height: 40px;
  padding-top: 12px;
}

.stats-card-content {
  flex: 50% !important;
  display: flex !important;
  flex-direction: column !important;
}

.subtitle {
  color: rgba(0, 0, 0, 0.77) !important;
  font-size: medium !important;
}

.subtitle-row {
  height: fit-content;
  padding: 0 !important;
}

.bordered {
  padding: 2px;
  border: 2px solid rgba(0, 0, 0, 0.10);
  width: 25%;
  min-width: fit-content;
  margin: .25em 0;
  height: 17px;
}

.view-panel {
  min-width: 400px !important;
  width: fit-content !important;
}

.view-panel-large {
  min-width: 1100px !important;
  width: fit-content !important;
}

.dialog-icon {
  vertical-align: middle;
  padding-bottom: 7px;
}

.green-icon {
  color: var(--green)
}

.half-width {
  width: 50% !important;
}

.overview-stats-card {
  min-width: 100px;
  min-height: 100px;
  width: 70%;
  height: 80%;
}

.overview-content {
  display: flex;
  font-size: x-large;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  height: 180px;
}

.center-text{
  margin: auto;
}