mat-table {
  border-radius: 10px 10px 0 0;
  border: 1px solid #0000001f;
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
}

.mat-header-row {
  background-color: #77777A10;
  border-radius: 10px 10px 0 0;
}

mat-paginator {
  background-color: transparent !important;
}