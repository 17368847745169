.sidenav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.selected-page-button {
  background-color: #EDAA003C;
}

.side-button {
  width: 100%;
  margin: 0px !important;
  padding: .5em 1em !important;
  text-align: left !important;
}

.uid-footer {
  height: 1em;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: rgb(237,170,0);
  background: linear-gradient(90deg, rgba(237,170,0,1) 0%, rgba(59,176,201,1) 50%, rgba(242,134,43,1) 100%);
}

mat-drawer-container {
  height: calc(100vh - 64px - 1em);
}

.main-content {
  margin: 0px 3em;
  margin-bottom: .5em;
}

.nav-toolbar {
  background-color: whitesmoke;
}


.sidenav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.selected-page-button {
  background-color: #EDAA003C;
}