html {
  font-family: Roboto, Helvetica Neue, sans-serif;
}
:root {
  --red: #EC5D57;
  --green: #70C143;
  --blue: #3BB0C9;
  --orange: #F2862B;
  --yellow:	#EDAA00;
  --gray: #77777A;
  --light-gray: #d7d7db;
}
body {
  margin: 0 !important;
}

.success-snackbar {
  background-color: var(--green);
}

.failure-snackbar {
  background-color: var(--red);
}

.section-header {
  background-color: transparent;
  padding: 0 !important;
}

.section-header-title {
  padding-left: 0 !important;
}

.warn-card {
  margin-top: 1em !important;
  background-color: var(--orange);
  color: white;
  font-weight: 500;
}

.spacer {
  flex: 1 1 auto;
}

.warning-text {
  color: var(--red);
}

.bolded {
  margin-top: .5em;
  font-weight: 450;
}

mat-divider {
  margin: 1em 0 !important;
}

.flex {
  display: flex !important;
}

button {
  height: fit-content;
}

.loader-text {
  margin-top: 3em;
}

.login-card {
  width: 50%;
}

.vertical-spacer{
  padding-top: 5px;
  padding-bottom: 5px;
}

.vertical-spacer-large{
  padding-top: 15px;
  padding-bottom: 15px;
}

.section-header-large {
  font-weight: 450;
  font-size: large;
}